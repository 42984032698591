import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { Profession } from '../interface/profession';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfessionService {
  private _isFromMatrix = true;

  constructor(
    private _http: HttpClient,
    private _dataService: DataService,
  ) { }

  public async getAll(): Promise<Profession[]> {
    const url = `${environment.mkgoURL}/api/v1/profession`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this._http.get(url, header).pipe(first()).toPromise();
    return (resp['professions'] as Profession[]).reverse();
  }

  public async getById(id: string): Promise<Profession> {
    const url = `${environment.mkgoURL}/api/v1/profession/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.get(url, header).pipe(first()).toPromise();
    return resp;
  }

  public async register(profession: Profession): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/profession`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this._http.post(url, JSON.stringify(profession), header).pipe(first()).toPromise();
    return resp['id'];
  }

  public async update(profession: Profession) {
    const url = `${environment.mkgoURL}/api/v1/profession/${profession._id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.put(url, JSON.stringify(profession), header).pipe(first()).toPromise();
    return resp;
  }
}
